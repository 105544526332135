<template>
  <v-btn-toggle mandatory borderless v-model="hotlineListView" class="elevation-2">
    <v-btn small>
      <v-icon small>mdi-view-day-outline</v-icon>
    </v-btn>
    <v-btn small>
      <v-icon small>mdi-view-list-outline</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import Vuex from "vuex"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "TicketListSwitchView",
  computed: {
    ...Vuex.mapState(["preferences"]),
    hotlineListView: {
      get() {
        return this.preferences.hotlineListView
      },
      set(val) {
        this.$store.commit("switchHotlineListView", val)

        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService.postInterface(store)
      }
    },
  }
}
</script>

<style scoped>

</style>